import { Box, Flex, Heading, Tag, Text } from "@chakra-ui/react";
import React from "react";
import Link from "next/link";
import { formatDate, ICategoryModel, JobStatus } from "..";

interface IJobListingTeaser {
  id: string;
  postedAt: string;
  status: JobStatus;
  title: string;
  shortDescription: string;
  industry: string;
  categories: ICategoryModel[];
  companySize: string;
  interestedMentors: string[];
}

export const JobListingTeaser: React.FC<IJobListingTeaser> = ({
  id,
  postedAt,
  title,
  shortDescription,
  categories,
  industry,
  interestedMentors,
}) => {
  return (
    <Box
      w={"100%"}
      border={"1px solid"}
      borderColor={"gray.200"}
      p={4}
      borderRadius={4}
    >
      <Flex justifyContent={"space-between"} mb={2}>
        <Text fontWeight={"bold"}>{`Veröffentlicht am: ${formatDate(
          postedAt
        )}`}</Text>
        <Tag size={"md"}>Interessenten: {interestedMentors.length}</Tag>
      </Flex>
      <Link href={`/jobboard/${id}`}>
        <Heading
          as={"h2"}
          fontSize={"lg"}
          py={4}
          textDecor={"underline"}
          _hover={{ cursor: "pointer", color: "gray.700", textDecor: "none" }}
        >
          {title}
        </Heading>
      </Link>
      <Text>{shortDescription}</Text>
      <Flex mt={4} flexWrap={"wrap"}>
        <Tag size={"md"} mr={2} mt={2}>
          {categories.join(" | ")}
        </Tag>
        <Tag size={"md"} mr={2} mt={2}>
          {industry}
        </Tag>
      </Flex>
    </Box>
  );
};
