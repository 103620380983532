import React from "react";
import {
  FormControl,
  Box,
  Radio,
  RadioGroup,
  Stack,
  Input,
  CheckboxGroup,
  Checkbox,
  FormErrorMessage,
  Text,
} from "@chakra-ui/react";
import { Field } from "formik";
import {
  HeaderDescriptionFormFieldWrapper,
  LocationEnum,
  FormFieldWrapper,
  Location,
} from "..";

export const LocationFormField: React.FC = ({}) => {
  const checkboxConfig = [
    {
      value: LocationEnum.ONLINE,
      defaultValue: "online",
      label: "Virtuell",
    },
    {
      value: LocationEnum.IN_HOUSE,
      label: "Präsenz",
    },
  ];

  return (
    <Box>
      <Field name={"location.type"}>
        {({ field, form }: any) => (
          <FormControl
            mb={10}
            isInvalid={
              form.errors?.location?.type && form.touched?.location?.type
            }
          >
            <HeaderDescriptionFormFieldWrapper
              title={"Leistungsort"}
              description="Gib an, ob du dein Angebot virtuell, in Präsenz oder beide Optionen
              anbietest. Denk daran, in der Beschreibung weitere Details zum
              Leistungsort bzw. bei virtuellen Angeboten zum Format und Tools
              anzugeben."
            >
              <Box mt={2}>
                <CheckboxGroup
                  {...field}
                  id="location.type"
                  name="location.type"
                  onChange={(nextValue: LocationEnum) => {
                    form.setFieldValue("location.type", nextValue);
                  }}
                  value={field.value}
                >
                  <Stack spacing={4} direction="row">
                    {checkboxConfig.map((element, index) => (
                      <Checkbox
                        key={index}
                        value={element.value}
                        defaultValue={element.defaultValue}
                      >
                        {element.label}
                      </Checkbox>
                    ))}
                  </Stack>
                </CheckboxGroup>
              </Box>
              {form.errors?.location?.type && (
                <Text color={"red.400"}>
                  Es muss mindestens ein Leistungsort ausgewählt werden
                </Text>
              )}
            </HeaderDescriptionFormFieldWrapper>
          </FormControl>
        )}
      </Field>

      <FormFieldWrapper
        title={"Beschreibung (optional)"}
        child={<Input />}
        inputConfig={{
          name: `location.comment`,
          placeholder: undefined,
        }}
      />
    </Box>
  );
};
