import { FirebaseApp, getApp, getApps, initializeApp } from "firebase/app";
import { firebaseConfig } from "./firebaseConfig";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import { getAuth } from "firebase/auth";

const DEFAULT_FUNCTIONS_REGION = "europe-west3";

let app: FirebaseApp;
if (!getApps.length) {
  app = initializeApp(firebaseConfig);
} else {
  app = getApp("admin-platform"); // if already initialized, use that one
}
export const db = getFirestore(app);

export const storage = getStorage(app);
export const firebaseFunction = getFunctions(app, DEFAULT_FUNCTIONS_REGION);
export const auth = getAuth(app);
export default app;
