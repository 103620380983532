import {
  Box,
  Heading,
  Text,
  Stack,
  Divider,
  useDisclosure,
} from "@chakra-ui/react";

import React from "react";
import { Button } from "ui";

import { Disclaimer } from "./Disclaimer";

interface IDetailsCars {
  mentorId: string;
  isButtonDisabled?: boolean;
}

export const DetailsCard: React.FC<IDetailsCars> = ({
  mentorId,
  isButtonDisabled = false,
}) => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  return (
    <>
      <Box pt={10}>
        <Box
          w={"full"}
          bg="white"
          boxShadow={"2xl"}
          rounded={"md"}
          overflow={"hidden"}
        >
          <Box py={1.5} backgroundColor="#0E1C72" borderRadius="6px 6px 0 0" />
          <Box p={8}>
            <Heading
              mb={5}
              color="primaryTextColor"
              fontSize={"2xl"}
              textAlign={"center"}
            >
              Mentor kontaktieren
            </Heading>

            <Text textAlign={"center"}>
              Hast du Fragen, möchtest einen Individualtermin oder den Mentor
              für einen Workshop buchen?
            </Text>

            <Stack justifyContent="center" mt={5}>
              <Divider mb={5} color="secundaryTextColor" />
              <Stack justifyContent="center" spacing={5}>
                <Button
                  label="Mentor kontaktieren"
                  type="solid"
                  isDisabled={isButtonDisabled}
                  onClick={onOpen}
                />
                <Disclaimer />
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Box>
    </>
  );
};
