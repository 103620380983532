import { CircularProgress, Flex } from "@chakra-ui/react";
import React from "react";

export const PageLoading: React.FC = () => {
  return (
    <Flex w="100vw" h="100vh" justifyContent={"center"} alignItems={"center"}>
      <CircularProgress isIndeterminate color="primaryColor" />
    </Flex>
  );
};
