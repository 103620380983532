import { IDbWorkshopModel } from "./db/dbWorkshopModel";
import { IHashtagModel } from "./hashtagModel";

export interface IWorkshopModel extends IDbWorkshopModel {}

export interface Option {
  readonly label: string;
  readonly value: string;
}

export const mapHashtagsArrayToOptions = (
  hashtags: IHashtagModel[]
): Option[] => {
  return hashtags.map((hashtag) => ({
    label: hashtag.name,
    value: hashtag.name,
  }));
};

export const mapHashtagsAsOptionToArray = (hashtags: Option[]) => {
  return hashtags.map((hashtag) => ({ name: hashtag.value }));
};
