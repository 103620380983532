import React from "react";

import { Field, ErrorMessage } from "formik";
import { Box, Flex, FormControl } from "@chakra-ui/react";
import { HeaderDescriptionFormFieldWrapper, WorkshopTypeEnum } from "..";

interface ITimePicker {
  name: string;
  title: string;
  description?: string;
}
export const TimePickerFormField: React.FC<ITimePicker> = ({
  title,
  name,
  description,
  ...rest
}) => {
  return (
    <Field name={name}>
      {/* @ts-ignore*/}
      {({ form, field }) => {
        const { value } = field;
        return (
          <>
            {form.values.dateTime.type === WorkshopTypeEnum.SCHEDULED ? (
              <FormControl
                mb={10}
                isInvalid={form.errors.title && form.touched.title}
              >
                <HeaderDescriptionFormFieldWrapper
                  title={title}
                  description={description}
                >
                  <Box mt={2}>
                    <Flex
                      width={"3xs"}
                      borderRadius={"4px"}
                      px={"8px"}
                      py={"2px"}
                      fontSize={"md"}
                      border={"1px solid hsl(0, 0%, 80%)"}
                      h={"36px"}
                      alignItems={"center"}
                      mt={4}
                    >
                      <input
                        id={name}
                        {...field}
                        {...rest}
                        type="time"
                        value={value}
                        step={300}
                      />
                    </Flex>
                    <ErrorMessage name={name || ""}>
                      {form.errors.description}
                    </ErrorMessage>
                  </Box>
                </HeaderDescriptionFormFieldWrapper>
              </FormControl>
            ) : null}
          </>
        );
      }}
    </Field>
  );
};
