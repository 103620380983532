import { Flex, Box, Text } from "@chakra-ui/react";
import React from "react";
import { FaRegThumbsUp } from "react-icons/fa";

export const Disclaimer = () => {
  return (
    <Flex justifyContent={"center"} alignItems={"center"}>
      <Box mr={4}>
        <FaRegThumbsUp fontSize={30} />
      </Box>

      <Box>
        <Text fontSize={"xs"}>
          Bitte nimm den Erstkontakt und Buchungsanfragen nur über die mentorlab
          Plattform vor, damit wir den Service weiterhin anbieten und verbessern
          können.
        </Text>
      </Box>
    </Flex>
  );
};
