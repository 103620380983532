import React from "react";
import { FormControl, Box, Divider } from "@chakra-ui/react";
import { Field } from "formik";
import {
  CreatableMultiSelect,
  HeaderDescriptionFormFieldWrapper,
  Option,
} from "..";
type CustomToastType = "error" | "success" | "info" | "warning";
interface IMultiSelectFormField {
  name: string;
  title: string;
  description?: string;
  isMulti?: boolean;
  allOptions: Option[];
  onCreateNewOption: (collectionName: string, option: Option) => Promise<void>;
  onSetCreateNewOptionError: (
    type: CustomToastType,
    msg: {
      title: string;
      description?: string;
    }
  ) => void;
}

export const CreatableMultiSelectFormField: React.FC<IMultiSelectFormField> = ({
  name,
  title,
  description,
  allOptions,
  isMulti = true,
  onCreateNewOption,
  onSetCreateNewOptionError,
}) => {
  return (
    <Box>
      <Field name={name}>
        {({ field, form }: any) => (
          <FormControl
            marginBottom={10}
            isInvalid={form.errors[name] && form.touched[name]}
          >
            <HeaderDescriptionFormFieldWrapper
              title={title}
              description={description}
            >
              <Box mt={2}>
                <CreatableMultiSelect
                  {...field}
                  id={name}
                  formValue={form.values}
                  setFieldValue={form.setFieldValue}
                  allOptions={allOptions}
                  isMulti={isMulti}
                  onCreateNewOption={onCreateNewOption}
                  onSetCreateNewOptionError={onSetCreateNewOptionError}
                />
              </Box>
            </HeaderDescriptionFormFieldWrapper>
          </FormControl>
        )}
      </Field>
      <Divider mb={10} />
    </Box>
  );
};
