import React, { useState } from "react";
import "react-dates/initialize";
import { DateRangePicker, FocusedInputShape } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import {
  FormControl,
  Box,
  Divider,
  Radio,
  RadioGroup,
  Stack,
  NumberInput,
  NumberInputField,
  Text,
} from "@chakra-ui/react";
import { Field } from "formik";
import { HeaderDescriptionFormFieldWrapper } from "..";
import { TimePickerFormField } from "./TimePickerFormField";
import { WorkshopTypeEnum } from "../types/db/dbWorkshopModel";

interface IDateTimeFormField {
  name: string;
  title: string;
  description?: string;
}

export const DateTimeFormField: React.FC<IDateTimeFormField> = ({
  name,
  title,
  description,
}) => {
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
    null
  );

  const radioButtonWorkshopTypeConfig = [
    {
      value: WorkshopTypeEnum.FREE,
      label: "Termin auf Anfrage",
    },
    {
      value: WorkshopTypeEnum.SCHEDULED,
      label: "Fixen Termin eingeben",
    },
  ];

  return (
    <Box>
      <Field name={"dateTime.type"}>
        {({ field, form }: any) => (
          <FormControl
            mb={10}
            isInvalid={
              form.errors["dateTime.type"] && form.touched["dateTime.type"]
            }
          >
            <HeaderDescriptionFormFieldWrapper title={"Termin"}>
              <Box mt={2}>
                <RadioGroup
                  {...field}
                  id="dateTime.type"
                  name="dateTime.type"
                  onChange={(nextValue: WorkshopTypeEnum) => {
                    // setWorkshopType(nextValue);
                    form.setFieldValue("dateTime.type", nextValue);
                    if (nextValue === WorkshopTypeEnum.FREE) {
                      form.setFieldValue("dateTime.startDate", null);
                      form.setFieldValue("dateTime.endDate", null);
                      form.setFieldValue("dateTime.startTime", null);
                      form.setFieldValue("dateTime.endTime", null);
                    }
                  }}
                  value={field.value}
                >
                  <Stack spacing={4} direction="row">
                    {radioButtonWorkshopTypeConfig.map((element, index) => (
                      <Radio key={index} value={element.value}>
                        {element.label}
                      </Radio>
                    ))}
                  </Stack>
                </RadioGroup>
              </Box>
            </HeaderDescriptionFormFieldWrapper>
          </FormControl>
        )}
      </Field>
      <Field name={"dateTime.duration"}>
        {({ field, form }: any) => (
          <FormControl
            mb={10}
            isInvalid={
              form.errors.dateTime?.duration && form.touched.dateTime?.duration
            }
          >
            <HeaderDescriptionFormFieldWrapper
              title={"Dauer des Workshops (Stunden)"}
            >
              <Box mt={2}>
                <NumberInput size="lg" maxW={32} min={1} {...field}>
                  <NumberInputField
                    onChange={form.handleChange}
                    id={name}
                    variant="filled"
                    value={field.value}
                  />
                </NumberInput>
              </Box>
              {form.errors?.dateTime?.duration && (
                <Text color={"red.400"}>
                  Bitte gebe die Dauer des Workshops an
                </Text>
              )}
            </HeaderDescriptionFormFieldWrapper>
          </FormControl>
        )}
      </Field>

      <>
        <Field name={"dateTime"}>
          {({ field, form }: any) => (
            <>
              {field.value.type === WorkshopTypeEnum.SCHEDULED ? (
                <FormControl
                  mb={10}
                  isInvalid={form.errors[name] && form.touched[name]}
                >
                  <HeaderDescriptionFormFieldWrapper
                    title={title}
                    description={description}
                  >
                    <Box mt={2}>
                      <DateRangePicker
                        startDate={form.values.dateTime.startDate}
                        startDateId="start-date"
                        endDate={form.values.dateTime.endDate}
                        endDateId="end-date"
                        onDatesChange={({ startDate, endDate }) => {
                          form.setFieldValue("dateTime.startDate", startDate);
                          form.setFieldValue(
                            "dateTime.endDate",
                            endDate?.format("DD.MM.YYYY")
                          );
                          form.setFieldValue("dateTime.endDate", endDate);
                        }}
                        minimumNights={0}
                        showDefaultInputIcon
                        focusedInput={focusedInput}
                        onFocusChange={(focusedInput) =>
                          setFocusedInput(focusedInput)
                        }
                      />
                    </Box>
                  </HeaderDescriptionFormFieldWrapper>
                </FormControl>
              ) : null}
            </>
          )}
        </Field>

        <TimePickerFormField name={"dateTime.startTime"} title={"Startzeit"} />

        <TimePickerFormField name={"dateTime.endTime"} title={"Endzeit"} />
        <Divider mb={10} />
      </>
    </Box>
  );
};
