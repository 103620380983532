import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
} from "@chakra-ui/react";
import React from "react";

interface ISlider {
  zoom: number;
  min: number;
  max: number;
  step: number;
  ariaLabel: string;
  onChangeHandler: (value: number) => void;
}
export const ImageSlider: React.FC<ISlider> = ({
  zoom,
  min,
  max,
  step,
  ariaLabel,
  onChangeHandler,
}) => (
  <Slider
    onChangeEnd={onChangeHandler}
    value={zoom}
    min={min}
    max={max}
    step={step}
    aria-label={ariaLabel}
  >
    <SliderTrack>
      <SliderFilledTrack />
    </SliderTrack>
    <SliderThumb />
  </Slider>
);
