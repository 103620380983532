import React from "react";
import {
  FormControl,
  Box,
  Divider,
  Flex,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import CurrencyInput from "react-currency-input-field";
import { Field } from "formik";
import { HeaderDescriptionFormFieldWrapper } from "..";
import { splitFieldName } from "../utils/formValidationHelper";

interface IPriceFormField {
  name: string;
  title: string;
  description?: string;
}

export const PriceFormField: React.FC<IPriceFormField> = ({
  name,
  title,
  description,
}) => {
  const intlConfig = {
    locale: "de-DE",
    currency: "EUR",
  };
  const splittedFieldName = splitFieldName(name);

  return (
    <Box>
      <Field name={"price.type"}>
        {({ field, form }: any) => (
          <FormControl mb={10}>
            <HeaderDescriptionFormFieldWrapper
              title={"Preisgestaltung"}
              description="Gib hier einen Gesamtpreis (zzgl. der jeweils gültigen gesetzlichen MwSt.)
              zu deinem Angebot an. Erläuterungen, Teilnehmerzahl, Nebenkosten,
              Staffelungen etc. kannst du in der Beschreibung angeben."
            >
              <Box mt={2}>
                <RadioGroup
                  {...field}
                  id="price.type"
                  name="price.type"
                  onChange={(nextValue) =>
                    form.setFieldValue("price.type", nextValue)
                  }
                  value={field.value}
                >
                  <Stack spacing={4} direction="row">
                    <Radio value="perParticipant">Pro Person</Radio>
                    <Radio value="total">Gesamt</Radio>
                  </Stack>
                </RadioGroup>
              </Box>
            </HeaderDescriptionFormFieldWrapper>
          </FormControl>
        )}
      </Field>
      <Field name={name}>
        {({ field, form }: any) => (
          <FormControl
            mb={10}
            isInvalid={
              splittedFieldName.secondPart
                ? form.errors[splittedFieldName.firstPart]?.[
                    splittedFieldName.secondPart
                  ] &&
                  form.touched[splittedFieldName.firstPart]?.[
                    splittedFieldName.secondPart
                  ]
                : form.errors[splittedFieldName.firstPart] &&
                  form.touched[splittedFieldName.firstPart]
            }
          >
            <HeaderDescriptionFormFieldWrapper
              title={title}
              description={description}
            >
              <Box mt={2}>
                <Flex
                  width={"100%"}
                  borderRadius={"4px"}
                  px={"8px"}
                  py={"2px"}
                  fontSize={"md"}
                  border={"1px solid hsl(0, 0%, 80%)"}
                  h={"36px"}
                  alignItems={"center"}
                  mt={4}
                >
                  <CurrencyInput
                    id={name}
                    name={name}
                    defaultValue={""}
                    decimalsLimit={2}
                    allowNegativeValue={false}
                    intlConfig={intlConfig}
                    onValueChange={(value, name) =>
                      form.setFieldValue(name, value)
                    }
                    value={field.value}
                  />
                </Flex>
              </Box>
              <Text mt={1} fontSize={"sm"} color={"red.400"}>
                {splittedFieldName.secondPart
                  ? form.errors[splittedFieldName.firstPart]?.[
                      splittedFieldName.secondPart
                    ]
                  : form.errors[splittedFieldName.firstPart]}
              </Text>
            </HeaderDescriptionFormFieldWrapper>
          </FormControl>
        )}
      </Field>
      <Divider mb={10} />
    </Box>
  );
};
