import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Stack,
  Image,
  Box,
  Text,
  Flex,
} from "@chakra-ui/react";
import { Button } from "..";

interface INotificationModal {
  title: string;
  description: { firstLine: string; secondLine: string };
  isOpen: boolean;
  buttonText?: string;
  illustrationUrl?: string;
  withCloseButton?: boolean;
  onClick: (data?: unknown) => void;
  onClose: () => void;
}

export const NotificationModal: React.FC<INotificationModal> = ({
  title,
  description,
  illustrationUrl,
  isOpen,
  buttonText = "Weiter",
  withCloseButton = true,
  onClick,
  onClose,
  children,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent p={4}>
        <ModalHeader textAlign={"center"}>{title}</ModalHeader>
        {withCloseButton && <ModalCloseButton />}

        <ModalBody pb={6}>
          <Stack spacing={6} alignItems={"center"}>
            <Image
              alt={`${title} - Illustration`}
              objectFit={"cover"}
              src={illustrationUrl}
            />
            <Box>
              <Text pb={2} textAlign={"center"}>
                {description.firstLine}
              </Text>
              <Text textAlign={"center"}>{description.secondLine}</Text>
            </Box>
            {children ? <Box pb={2}>{children}</Box> : null}
            <Flex w={"100%"} justifyContent={"flex-end"}>
              <Button label={buttonText} type={"solid"} onClick={onClick} />
            </Flex>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
