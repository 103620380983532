import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  UserCredential,
  User,
  sendEmailVerification,
} from "firebase/auth";
import React, { createContext, useContext, useEffect, useState } from "react";

import { auth } from "../firebase";

interface IAuthContext {
  currentUser: User | null;
  signup: (email: string, password: string) => Promise<void>;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  sendVerificationEmail: (user: User) => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  isVerifiedEmail: (user: User) => boolean;
}
interface IAuthProvider {
  children: React.ReactNode;
}
//@ts-ignore
export const AuthContext = createContext<IAuthContext>();

export const useAuth = () => {
  return useContext(AuthContext);
};
export const AuthProvider: React.FC<IAuthProvider> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const signup = async (email: string, password: string) => {
    await createUserWithEmailAndPassword(auth, email, password);
  };

  const login = async (email: string, password: string) => {
    await signInWithEmailAndPassword(auth, email, password);
  };

  const logout = async () => {
    await auth.signOut();
    setCurrentUser(null);
  };

  const resetPassword = async (email: string) => {
    await sendPasswordResetEmail(auth, email);
  };

  // const isLocal = window.location.hostname === "localhost";

  const sendVerificationEmail = async (user: User) => {
    // const localUrl = `http://${window.location.hostname}:3004/login`;
    const url = `https://${window.location.hostname}/login`;
    // const urlFromEnv = `${process.env.NEXT_PUBLIC_REDIRECT_URL}/login`
    let actionCodeSettings = {
      // url: isLocal ? localUrl : url,
      url,
      handleCodeInApp: true,
    };
    await sendEmailVerification(user, actionCodeSettings);
    await auth.signOut();
  };

  const isVerifiedEmail = (user: User) => {
    return user.emailVerified;
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  const value = {
    currentUser,
    signup,
    login,
    logout,
    resetPassword,
    sendVerificationEmail,
    isVerifiedEmail,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
